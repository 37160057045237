import { ModalBody, Box } from "@twilio-paste/core";
import { RefObject, useState } from "react";
import ModalInputField from "./ModalInputField";
import AddParticipantFooter from "./addParticipantFooter";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import { AppState } from "../../store";
import { getTranslation } from "../../utils/localUtils";
import { useSelector } from "react-redux";

interface AddSMSParticipantModalProps {
  name: string;
  isModalOpen: boolean;
  title: string;
  proxyName: string;
  setName: (name: string) => void;
  setProxyName: (name: string) => void;
  error: string;
  errorProxy: string;
  nameInputRef: RefObject<HTMLInputElement>;
  handleClose: () => void;
  onBack: () => void;
  action: () => void;
  participantsCount: number; // New prop to track current participants count
}

const AddSMSParticipantModal: React.FC<AddSMSParticipantModalProps> = (
  props: AddSMSParticipantModalProps
) => {
  const local = useSelector((state: AppState) => state.local);
  const smsNum = getTranslation(local, "smsNum");
  const addSMSParticipant = getTranslation(local, "addSMSParticipant");

  const [participantError, setParticipantError] = useState("");

  const handleAddParticipant = () => {
    if (props.participantsCount >= 2) {
      setParticipantError("Impossible d'ajouter plus de 1 participant.");
      return;
    }

    setParticipantError("");
    props.action();
  };

  return (
    <>
      <ConvoModal
        handleClose={props.handleClose}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <h3>{addSMSParticipant}</h3>
            {participantError && (
              <p style={{ color: "red" }}>{participantError}</p>
            )}
            <Box
              as="form"
              onKeyPress={async (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddParticipant();
                }
              }}
            >
              <ModalInputField
                isFocused={true}
                label={smsNum}
                input={props.name}
                onChange={props.setName}
                error={props.error}
                prefixType="SMS"
              />
            </Box>
          </ModalBody>
        }
        modalFooter={
          <AddParticipantFooter
            actionName={ActionName.Save}
            onBack={props.onBack}
            action={handleAddParticipant}
          />
        }
      />
    </>
  );
};

export default AddSMSParticipantModal;
