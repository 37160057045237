import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { Client } from "@twilio/conversations";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
} from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Text } from "@twilio-paste/text";

import { actionCreators } from "../../store";
import { MAX_FILE_SIZE } from "../../constants";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import MessageInput from "./MessageInput";
import SendMessageButton from "./SendMessageButton";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { getSdkConversationObject } from "../../conversations-objects";
import { ReduxMessage } from "../../store/reducers/messageListReducer";

interface SendMessageProps {
  convoSid: string;
  client: Client;
  messages: ReduxMessage[];
  convo: ReduxConversation;
  typingData: string[];
  droppedFiles: File[];
}

const predefinedMessages = [
  {
    id: "Suivi de prospection - FR",
    text:
      "Bonjour, ici l'équipe de Nautilus Plus,\n" +
      "Vous nous avez visités récemment. Avez-vous aimé nos installations?\n" +
      "Seriez-vous prêt à commencer l’entraînement?\n" +
      "Répondez OUI si vous souhaitez en savoir plus.",
  },
  {
    id: "Suivi de prospection - EN",
    text:
      "Hello, this is the Nautilus Plus team,\n" +
      "You recently visited us. Did you like our facilities?\n" +
      "Would you be willing to start training?\n" +
      "Answer YES if you would like to know more.",
  },
  {
    id: "Renouvellement d'abonnement - FR",
    text:
      "Bonjour,\n" +
      "Ici l'équipe de Nautilus Plus, on espère que vous profitez pleinement de tous les avantages d’être membre N+.\n" +
      "C’est maintenant le moment de renouveler votre abonnement avec nous!\n" +
      "Aurons-nous le privilège de vous côtoyer encore cette année?\n",
  },
  {
    id: "Renouvellement d'abonnement - EN",
    text:
      "Hello!\n" +
      "This is the Nautilus Plus team, we hope you are enjoying your access to our facilities and all the benefits of being an N+ member.\n" +
      "Now is the time to renew your membership with us!\n" +
      "Will we have the privilege of seeing you again this year?\n",
  },
  {
    id: "Renouvellement --- Réponse positive - FR",
    text: "Génial! Avez-vous besoin d'aide pour renouveler?",
  },
  {
    id: "Renouvellement --- Réponse positive - EN",
    text: "Great! Do you need help renewing?",
  },
  {
    id: "Renouvellement --- Réponse négative - FR",
    text: "D'accord, nous allons rester avec vous pour l'année prochaine.",
  },
  {
    id: "Renouvellement --- Réponse négative - EN",
    text: "Okay, we will stay in touch for the next year.",
  },
  {
    id: "Relance consultation initiale - FR",
    text:
      "Bonjour!\n" +
      "\n" +
      "ici votre centre Nautilus Plus.\n" +
      "\n" +
      "Il est temps de bouquer votre séance de consultation initiale gratuite avec un de nos kinésiologues. Seriez-vous disponible dans les prochains jours, durant la journée ou en soirée?",
  },
  {
    id: "Relance consultation initiale --- Réponse négative - FR",
    text:
      "N’oubliez pas, vous avez 30 jours pour utiliser votre séance initiale avec un de nos kinésiologues. C’est une parfaite occasion de discuter de vos objectifs et d’une stratégie pour rester motivé au gym!\n" +
      "\n" +
      "Au plaisir!",
  },
  {
    id: "Relance consultation initiale - EN",
    text:
      "This is your Nautilus Plus center" +
      "\n" +
      "It's time to book your free initial consultation with one of our kinesiologists. Would you be available in the next few days, during the day or in the evening?",
  },
  {
    id: "Relance consultation initiale --- Réponse négative - EN",
    text:
      "Remember, you have 30 days to use your initial session with one of our kinesiologists. It's the perfect opportunity to discuss your goals and a strategy for staying motivated at the gym!\n" +
      "Looking forward to hearing from you!",
  },
];

const MessageInputField: React.FC<SendMessageProps> = (
  props: SendMessageProps
) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [filesInputKey, setFilesInputKey] = useState<string>("input-key");

  const theme = useTheme();
  const typingInfo = getTypingMessage(props.typingData);

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setMessage("");
    setFiles([]);
    setFilesInputKey(Date.now().toString());
  }, [props.convo]);

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString());
    }
  }, [files]);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: assets } = event.target;
    if (!assets?.length) {
      return;
    }

    const validFiles = Array.from(assets).filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );

    if (validFiles.length < assets.length) {
      // TODO: show error
    }

    setFiles([...files, ...validFiles]);
  };

  const onMessageSend = async () => {
    if (message.length == 0 && files.length == 0) {
      return;
    }

    const { convo } = props;
    const sdkConvo = getSdkConversationObject(convo);

    const newMessageBuilder = sdkConvo.prepareMessage().setBody(message);

    for (const file of files) {
      const fileData = new FormData();
      fileData.set(file.name, file, file.name);
      newMessageBuilder.addMedia(fileData);
    }

    setMessage("");
    setFiles([]);
    const messageIndex = await newMessageBuilder.build().send();

    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
    } catch (e) {
      unexpectedErrorNotification(e.message, addNotifications);
      throw e;
    }
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const selectMessage = (msg: { id: string; text: string }) => {
    setMessage(msg.text); // Remplace le message actuel par le texte sélectionné
    closeModal();
  };

  return (
    <Box
      display="flex"
      flexBasis="60px"
      flexGrow={10}
      flexDirection="column"
      borderTopStyle="solid"
      borderTopWidth="borderWidth10"
      style={{
        borderTopColor: theme.borderColors.colorBorderWeak,
        backgroundColor: theme.backgroundColors.colorBackgroundBody,
      }}
    >
      <Box
        paddingBottom="space20"
        paddingTop="space50"
        paddingLeft="space150"
        hidden={!props.typingData.length}
      >
        <Text as="p" color="colorTextIcon">
          {typingInfo}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        flexGrow={10}
        paddingBottom="space30"
        paddingTop="space40"
      >
        <Box
          paddingBottom="space30"
          paddingLeft="space50"
          paddingRight="space10"
          paddingTop="space20"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="start"
        >
          <Button variant="link">
            <label htmlFor="file-input">
              <AttachIcon
                decorative={true}
                title="Attach file"
                size="sizeIcon50"
              />
            </label>
            <input
              id="file-input"
              key={filesInputKey}
              type="file"
              style={{ display: "none" }}
              onChange={onFilesChange}
            />
          </Button>
        </Box>
        <Box
          paddingBottom="space30"
          paddingLeft="space50"
          paddingRight="space10"
          paddingTop="space20"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="start"
        >
          <Button variant="link" onClick={openModal}>
            <PlusIcon
              decorative={true}
              title="Template message"
              size="sizeIcon50"
            />
          </Button>
        </Box>
        <Box paddingRight="space50" flexGrow={10}>
          <MessageInput
            assets={files}
            message={message}
            onChange={(e: string) => {
              sdkConvo.typing();
              setMessage(e);
            }}
            onEnterKeyPress={async () => {
              await onMessageSend();
            }}
            onFileRemove={(file: string) =>
              setFiles(files.filter((f) => f.name !== file))
            }
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="start"
        >
          {message || files.length ? (
            <SendMessageButton message={message} onClick={onMessageSend} />
          ) : null}
        </Box>
      </Box>

      {showModal && (
        <Modal size="default" ariaLabelledby="" isOpen onDismiss={closeModal}>
          <ModalHeader>
            <ModalHeading>Choisissez un message</ModalHeading>
          </ModalHeader>
          <ModalBody>
            <Box
              paddingTop="space10"
              paddingBottom="space10"
              display="grid"
              rowGap="space30"
            >
              {predefinedMessages.map((msg, index) => (
                <Button
                  key={index}
                  variant="primary"
                  onClick={() => selectMessage(msg)}
                >
                  {msg.id}
                </Button>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={closeModal}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Box>
  );
};

export default MessageInputField;
